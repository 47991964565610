import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useLocalStorage } from 'react-use';

import * as authAPI from 'api/authentication';
import * as userAPI from 'api/user';

import token from 'services/api/token';
import { log } from 'utils/functions';

import {
  CCP_COOKIE_NAME,
  GUEST_TOKEN,
  JWT_COOKIE_NAME,
  USER_COOKIE_NAME,
  USER_CREDIT_COOKIE_NAME,
  USER_FOLLOWING_BRANDS_COOKIE_NAME,
} from 'constants/index';
import AuthContext from 'context/AuthContext';

import routes from 'constants/routes';
import { NEXT_PUBLIC_BFF_URL, NEXT_PUBLIC_COOKIE_DOMAIN } from 'constants/runtimeConfig';
import useConfigs from 'hooks/useConfigs';
import { getCookie, getJwtFromCookie } from 'utils/cookies';
import { GTMPostSignupEvent, GTMUserChangeEvent } from 'utils/gtm';
import { syncUserCookieAcceptance } from 'utils/syncCookie.util';
import apiFactory from 'services/api/axios';
import { serverLogger } from 'utils/serverLogs.utils';
import { registerCustomerForPayment } from 'utils/payment.utils';

const COOKIE_DOMAIN = NEXT_PUBLIC_COOKIE_DOMAIN;
const COOKIE_OPTIONS = { path: '/', domain: COOKIE_DOMAIN, maxAge: 604800 }; // 7 days
const COOKIE_TTL = 12 * 60 * 60 * 1000; // 12 hours
const COOKIE_RENEW_THRESHOLD = 1 * 60 * 60 * 1000; // 1 hour
const COOKIE_CHECK_INTERVAL = 20 * 60 * 1000; // 20 minutes

const AuthProvider = ({ children, userDetails, userCreditDetails }) => {
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies([JWT_COOKIE_NAME, CCP_COOKIE_NAME]);
  const [userCredit, setUserCredit] = useState(null);
  const { getConfigs, removeConfigs } = useConfigs();
  const [registeringStripeCustomer, setRegisteringStripeCustomer] = useState(false);

  // user data
  const [user, setUser, removeUser] = useLocalStorage(USER_COOKIE_NAME, null);
  const [userId, setUserId, removeUserId] = useLocalStorage('userId', null);
  const [retailerId, setRetailerId, removeRetailerId] = useLocalStorage('retailerId', null);

  const renewUserCookieRef = useRef(cookies.user);
  const renewUserCreditCookieRef = useRef(cookies.userCredit);

  // register user with stripe if it not yet registered
  const registerStripeCustomer = async (userInfo) => {
    const jwt = token.get();
    if (!userInfo.retailerStripeCustomerId && !registeringStripeCustomer) {
      setRegisteringStripeCustomer(true);
      const { customerId } = await registerCustomerForPayment({
        user: userInfo,
        jwt,
        provider: 'stripe',
      });
      if (customerId) {
        const newUserInfo = { ...userInfo, retailerStripeCustomerId: customerId };
        setCookie(USER_COOKIE_NAME, newUserInfo, COOKIE_OPTIONS);
        renewUserCookieRef.current = newUserInfo;
        setUser(newUserInfo);
      }
      setRegisteringStripeCustomer(false);
    }
  };

  useEffect(() => {
    const jwt = getJwtFromCookie({ cookies });

    if (jwt === GUEST_TOKEN) {
      setIsAuthenticated(false);
    } else {
      setIsAuthenticated(true);
    }
  }, [cookies, userId]);

  // update userDetails
  useEffect(() => {
    const userCookie = renewUserCookieRef.current;
    const rawUserCookie = getCookie(USER_COOKIE_NAME); // this detects if the cookie was deleted

    if (
      typeof userDetails !== 'function' &&
      userDetails !== undefined &&
      userDetails &&
      (userDetails.id !== user?.id || !rawUserCookie || userCookie?.id !== userDetails.id)
    ) {
      setUser(userDetails);
      GTMUserChangeEvent(userDetails);
      setUserId(userDetails.id);
      setRetailerId(userDetails.retailerId);
      setCookie(USER_COOKIE_NAME, userDetails, COOKIE_OPTIONS);
      renewUserCookieRef.current = userDetails;
      token.set(cookies.creoate_user_jwt);
      registerStripeCustomer(userDetails);
    }
  }, [userDetails, setCookie, setRetailerId, setUser, setUserId, cookies]);

  // update userCreditDetails
  useEffect(() => {
    if (typeof userDetails !== 'function' && userDetails !== undefined && userDetails) {
      const newUserCreditDetails = cookies?.[USER_CREDIT_COOKIE_NAME] || {};
      if (userCreditDetails?.creditLimit) {
        newUserCreditDetails.creditLimit = userCreditDetails.creditLimit;
      }
      if (userCreditDetails?.creditBalance) {
        newUserCreditDetails.creditBalance = userCreditDetails.creditBalance;
      }
      if (userCreditDetails?.upfrontPaymentPercentage) {
        newUserCreditDetails.upfrontPaymentPercentage = userCreditDetails.upfrontPaymentPercentage;
      }
      if (userCreditDetails?.paymentDays) {
        newUserCreditDetails.paymentDays = userCreditDetails.paymentDays;
      }
      // Intentionally using "==" null to check for null or undefined
      if (!(userCreditDetails?.overdueAmount == null)) {
        newUserCreditDetails.overdueAmount = userCreditDetails.overdueAmount;
      }
      setUserCredit(newUserCreditDetails);
      renewUserCreditCookieRef.current = newUserCreditDetails;
      setCookie(USER_CREDIT_COOKIE_NAME, newUserCreditDetails, COOKIE_OPTIONS);
    }
  }, [userCreditDetails, setCookie, setUserCredit, cookies]);

  const removeWordPressCookie = useCallback(() => {
    const cookieNames = document.cookie.split(';');
    cookieNames.forEach((cookie) => {
      if (cookie.indexOf('wordpress') !== -1 || cookie.indexOf('woocommerce') !== -1) {
        removeCookie(cookie, COOKIE_OPTIONS);
      }
    });
  }, [removeCookie]);

  const cleanUpCreditAndOverdue = useCallback(() => {
    removeCookie(USER_CREDIT_COOKIE_NAME, COOKIE_OPTIONS);
  }, [removeCookie]);

  const fetchAndUpdateCreditAndOverdue = useCallback(async () => {
    const jwtToken = cookies[JWT_COOKIE_NAME];

    serverLogger.info(
      `Fetching user's credit async for retailerId: ${retailerId}`,
      { retailerId },
      'authProvider',
      'client'
    );
    // Fetch user's credit details
    const newCreditDetails =
      (
        await apiFactory({
          baseURL: NEXT_PUBLIC_BFF_URL,
          headers: { Authorization: jwtToken },
          params: { includeBalance: 'true' },
        }).get(`/users/${userId}/credit`)
      )?.data || {};

    const overdueData =
      (
        await apiFactory({
          baseURL: NEXT_PUBLIC_BFF_URL,
          headers: { Authorization: jwtToken },
          params: { includeBalance: 'true' },
        }).get(`/users/${userId}/overdueAmount`)
      )?.data?.data || {};

    // Merge the overdue amount into the credit details
    newCreditDetails.overdueAmount = overdueData.overdueAmount;
    serverLogger.info(
      `Setting new credit and overdue amount (merged) for retailerId: ${retailerId}`,
      { retailerId, newCreditDetails },
      'authProvider',
      'client'
    );

    setUserCredit(newCreditDetails);
    setCookie(USER_CREDIT_COOKIE_NAME, newCreditDetails, COOKIE_OPTIONS);
    renewUserCreditCookieRef.current = newCreditDetails;
    return newCreditDetails;
  }, [retailerId, setCookie, userId]);

  const logout = useCallback(
    (shouldRedirect = true, redirectTo = '/') => {
      // jwt
      token.clear();
      removeCookie(JWT_COOKIE_NAME, COOKIE_OPTIONS);
      removeCookie(USER_COOKIE_NAME, COOKIE_OPTIONS);
      removeCookie(USER_CREDIT_COOKIE_NAME, COOKIE_OPTIONS);
      removeCookie(USER_FOLLOWING_BRANDS_COOKIE_NAME, COOKIE_OPTIONS);
      removeWordPressCookie();
      // user data
      removeUserId();
      removeRetailerId();
      removeUser();
      removeConfigs();

      if (shouldRedirect) {
        router.push(redirectTo);
      }
    },
    [removeCookie, router, removeRetailerId, removeUser, removeUserId, removeWordPressCookie, removeConfigs]
  );

  const updateUserDetails = useCallback(
    (details) => {
      setUser(details);
      setUserId(details.id);
      setRetailerId(details.retailerId);
      setCookie(USER_COOKIE_NAME, details, COOKIE_OPTIONS);
      renewUserCookieRef.current = details;
      registerStripeCustomer(details);
    },
    [setCookie, setRetailerId, setUser, renewUserCookieRef]
  );

  const getUserDetails = useCallback(
    async (userIdToFetch) => {
      try {
        const details = await userAPI.getUserDetails(userIdToFetch);
        updateUserDetails(details);
        return details;
      } catch (e) {
        log.error('Error on getUserDetails. Error: ', e);
        return Promise.reject(e);
      }
    },
    [updateUserDetails]
  );

  const getCurrentUserDetails = useCallback(async () => {
    return getUserDetails(userId);
  }, [userId]);

  const getUserAndUpdateGTM = useCallback(
    async (userIdToFetch) => {
      try {
        const details = await userAPI.getUserDetails(userIdToFetch);
        updateUserDetails(details);

        const gtmUserDetails = {
          userId: details.userId || null,
          userCountry: details.user?.shippingCountry || null,
          storeType: details.user?.storeType || null,
          email: details.user?.email || null,
          companyHouseNumber: details.user?.companyHouseNumber || null,
          businessType: details.user?.businessType || null,
        };

        GTMPostSignupEvent(gtmUserDetails);

        return details;
      } catch (e) {
        log.error('Error on getUserDetails. Error: ', e);
        return Promise.reject(e);
      }
    },
    [updateUserDetails]
  );

  const renewCookie = (cookieRef, cookieName, fetchCallback) => {
    const cookie = cookieRef.current;
    // check if JWT is present and valid and if the user cookie is not present or about to expire
    if (cookie) {
      const currentTime = new Date().getTime();
      const expirationTime = cookie.expires ? new Date(cookie.expires).getTime() : currentTime;
      const timeUntilExpiration = expirationTime - currentTime;

      // Renew the cookie if it is close to expiring (e.g., within 5 minutes)
      if (timeUntilExpiration < COOKIE_RENEW_THRESHOLD) {
        const newExpirationTime = new Date(currentTime + COOKIE_TTL); // 12h from now
        serverLogger.info(
          `Cookie is about to expire. Renewing cookie: ${cookieName}`,
          { cookieName, cookie, expirationTime, timeUntilExpiration, newExpirationTime },
          'authProvider',
          'client'
        );
        fetchCallback().then((updatedCookieDetails) => {
          const cookieValue = { ...updatedCookieDetails, expires: newExpirationTime.toUTCString() };

          cookieRef.current = cookieValue; // eslint-disable-line no-param-reassign
          setCookie(cookieName, cookieValue, COOKIE_OPTIONS);
        });
      }
    }
  };

  useEffect(() => {
    const userCookieInterval = setInterval(() => {
      renewCookie(renewUserCookieRef, USER_COOKIE_NAME, getCurrentUserDetails);
    }, COOKIE_CHECK_INTERVAL); // Check every 20 minutes
    const userCreditCookieInterval = setInterval(() => {
      renewCookie(renewUserCreditCookieRef, USER_CREDIT_COOKIE_NAME, fetchAndUpdateCreditAndOverdue);
    }, COOKIE_CHECK_INTERVAL); // Check every 20 minutes
    return () => {
      clearInterval(userCookieInterval);
      clearInterval(userCreditCookieInterval);
    };
  }, [renewCookie]);

  // context handler
  const login = useCallback(
    async ({ identifier, password }, { shouldRedirect = false, redirectTo = '/' } = {}) => {
      try {
        const loggedInData = await authAPI.login(identifier, password);

        if (loggedInData) {
          token.set(loggedInData.jwt);
          setCookie(JWT_COOKIE_NAME, loggedInData.jwt, COOKIE_OPTIONS);
          setUserId(loggedInData.userId);

          await Promise.all([getConfigs(), getUserDetails(loggedInData.userId)]);

          if (cookies[CCP_COOKIE_NAME]) {
            syncUserCookieAcceptance();
          }

          enqueueSnackbar('Login successful', {
            variant: 'success',
          });

          if (shouldRedirect) {
            await router.push(redirectTo);
          }

          return loggedInData;
        }
        return loggedInData;
      } catch (error) {
        log.error({ error });

        logout(true, routes.auth.login);

        if (error.data?.errorCode === 'AUTH001') {
          enqueueSnackbar(`Couldn't log user in. Please review your credentials and try again`, {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(`Couldn't log user in. Error code ${error.data?.errorCode}. Please contact support`, {
            variant: 'error',
          });
        }

        return Promise.reject(error);
      }
    },
    [setCookie, setUserId, getUserDetails, cookies, enqueueSnackbar, router, logout, getConfigs]
  );

  const resetPassword = useCallback(
    async (e) => {
      try {
        await authAPI.resetPassword(e);

        enqueueSnackbar('Recovered password successfully', {
          variant: 'success',
        });
      } catch (error) {
        log.error('Error on resetPassword. Error: ', error);

        enqueueSnackbar(`Couldn't recover password. Please review your email and try again`, {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar]
  );

  const state = useMemo(
    () => ({
      isAuthenticated,
      userId,
      retailerId,
      user,
      login,
      logout,
      resetPassword,
      userCredit,
      getUserAndUpdateGTM,
      fetchAndUpdateCreditAndOverdue,
      cleanUpCreditAndOverdue,
    }),
    [
      isAuthenticated,
      userId,
      retailerId,
      user,
      login,
      logout,
      resetPassword,
      userCredit,
      getUserAndUpdateGTM,
      fetchAndUpdateCreditAndOverdue,
      cleanUpCreditAndOverdue,
    ]
  );

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
  userDetails: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    displayName: PropTypes.string,
    shippingCountry: PropTypes.string,
    retailerId: PropTypes.string,
    paymentDays: PropTypes.number,
    creditLimit: PropTypes.number,
  }),
  userCreditDetails: PropTypes.shape({
    creditLimit: PropTypes.number,
    creditBalance: PropTypes.number,
    upfrontPaymentPercentage: PropTypes.number,
    paymentDays: PropTypes.number,
  }),
};

export default AuthProvider;
